.bxslider {
  background: #ffac34;
  color: #fff;
  text-align: center;
  padding: 0;
  margin: 0;
  border: 0px !important;
}

.bxslider li {
  font-size: 18px;
  padding: 21px 0;
}

.bx-wrapper {
  border: 0px !important;
  margin-bottom: 0px;
}